import { AnchorHTMLAttributes, ElementType, forwardRef, MouseEvent, ReactNode } from 'react';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { stringify, ParsedUrlQueryInput } from 'querystring';
import classNames from 'classnames';

import config from 'config';
import type { TrackLink } from 'utils/analytics';
import removeEmptyArrays from 'utils/removeEmptyArrays';

import isAbsoluteUrl from 'utils/isAbsoluteUrl';
import { scrollToAnchor, scrollToTop } from 'utils/scroll';
import classes from './Link.module.scss';

const { withPrefetch } = config;

export type Props = {
  children: ReactNode;
  href: LinkProps['href'];
  as?: string;
  underline?: 'always' | 'hover' | 'none';
  scroll?: LinkProps['scroll'];
  shallow?: LinkProps['shallow'];
  openInNewTab?: boolean;
  trackEvent?: ReturnType<TrackLink>;
  dataEid?: string;
  component?: ElementType;
} & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;

const CustomLink = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      href,
      as,
      scroll,
      shallow,
      underline = 'none',
      children,
      className,
      openInNewTab,
      rel,
      dataEid,
      component: Component = Link,
      trackEvent,
      onClick,
      ...rest
    },
    ref
  ): JSX.Element => {
    const router = useRouter();
    const isAbsolute = typeof href === 'string' && isAbsoluteUrl(href);
    const isScrollToElement = typeof href === 'string' && href[0] === '#';
    const isRealLink = Component === Link;

    const handleSmoothScroll = (e: MouseEvent<HTMLAnchorElement>) => {
      if (typeof onClick === 'function') {
        onClick(e);
      } else if (isScrollToElement) {
        e.preventDefault();
        scrollToAnchor(href.slice(1));
        setTimeout(() => {
          window.location.hash = href;
        }, 300);
      } else if (scroll) {
        scrollToTop();
      }
    };

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
      if (trackEvent) {
        e.preventDefault();
        trackEvent(() => {
          if (openInNewTab) {
            window.open(`${href}`);
          } else if (isAbsolute) {
            window.location.href = href;
          } else {
            router.push(href);
            handleSmoothScroll(e);
          }
        });
      } else {
        if (!isRealLink || as) {
          e.preventDefault();
          router.push(href);
        }
        handleSmoothScroll(e);
      }
    };

    const anchorProps = {
      ref,
      'data-eid': dataEid,
      ...rest,
      className: classNames(className, classes.link, {
        [classes.hover]: underline === 'hover',
        [classes.underline]: underline === 'always',
      }),
      onClick: isScrollToElement || scroll || as || onClick || trackEvent || !isRealLink ? handleClick : undefined,
      target: openInNewTab ? '_blank' : undefined,
      rel: openInNewTab ? 'noopener noreferrer' : rel,
    };

    const finalHref =
      as ||
      (typeof href !== 'string'
        ? {
            ...href,
            query: stringify(removeEmptyArrays((href?.query || {}) as ParsedUrlQueryInput)),
          }
        : href);

    return isAbsolute ? (
      <a {...anchorProps} href={as || href}>
        {children}
      </a>
    ) : (
      <Component
        href={(isRealLink ? finalHref : undefined) as LinkProps['href']}
        scroll={isRealLink ? scroll : undefined}
        shallow={shallow}
        prefetch={withPrefetch || !isRealLink ? undefined : false}
        {...anchorProps}
      >
        {children}
      </Component>
    );
  }
);

export default CustomLink;
