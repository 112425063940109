import Price from 'types/Price';
import formatAmount from 'utils/formatAmount';

type Props = {
  price: Price;
};

const AmountFormatter = ({ price }: Props): JSX.Element => <>{formatAmount(price)}</>;

export default AmountFormatter;
