import { ElementType } from 'react';
import classNames from 'classnames';

import SkeletonLoaderIcon from 'assets/icons/skeleton-loader.svg';
import clearObject from 'utils/clearObject';

import classes from './SkeletonLoader.module.scss';

export type Props = {
  className?: string;
  icon?: ElementType;
  background?: 'white' | 'gray' | 'transparent';
  iconWidth: number;
  iconHeight?: number;
  isExternal?: boolean;
  isAnimationPuased?: boolean;
  hasFade?: boolean;
};

const SkeletonLoader = ({
  className,
  icon: Icon = SkeletonLoaderIcon,
  iconWidth,
  iconHeight,
  background = 'gray',
  isExternal = false,
  isAnimationPuased = false,
  hasFade = true,
}: Props): JSX.Element => {
  const iconProps = clearObject({ width: iconWidth, height: iconHeight });

  return (
    <div
      className={classNames(className, classes.wrapper, classes[background], {
        [classes.hasFade]: hasFade,
        [classes.isExternal]: isExternal,
      })}
    >
      <div className={classes.loaderWrapper}>
        <Icon className={classNames(classes.shine, { [classes.paused]: isAnimationPuased })} {...iconProps} />
        <Icon className={classes.loader} {...iconProps} />
      </div>
    </div>
  );
};

export default SkeletonLoader;
