import intlConfig from 'config/i18n';
import Price from 'types/Price';

export type NumberFormatOptions = Omit<Intl.NumberFormatOptions, 'signDisplay'> & {
  signDisplay?: Intl.NumberFormatOptions['signDisplay'] | 'exceptZero';
};

type UseFormatAmountReturn = (price: Price, options?: NumberFormatOptions) => string;

const formatAmount = (price: Price, options?: NumberFormatOptions): string => {
  let result;
  try {
    result = Intl.NumberFormat(intlConfig.intl.locale, {
      style: 'currency',
      currency: price.currency,
      ...(options as Intl.NumberFormatOptions),
    })
      .format(price.amount / 100)
      .replace(/([1-9])([0-9]{3})/, `$1\xA0$2`); // to add missing thousand separator, when amount is between 1000-9999 (not present in PL locale)
  } catch {
    result = `${price.amount / 100} ${price.currency}`;
  }

  return result;
};
const useFormatAmount =
  (): UseFormatAmountReturn =>
  (price, options = {}) =>
    formatAmount(price, options);

export default formatAmount;
export { useFormatAmount };
