import { appContainerSelector } from 'consts/app';

const scrollTo = (selector: string): void => {
  document.querySelector(selector)?.scrollIntoView({
    behavior: 'smooth',
  });
};

const scrollToAnchor = (anchorId: string): void => {
  document.getElementById(anchorId)?.scrollIntoView({
    behavior: 'smooth',
  });
};

const scrollToTop = (): void => {
  document.querySelector(appContainerSelector)?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export { scrollTo, scrollToAnchor, scrollToTop };
