import { Product } from 'types/models/Product';
import { ProductType } from 'types/models/ProductType';
import ImageFile from 'types/ImageFile';

type Variant = {
  thumbnail?: ImageFile;
  product: Pick<Product, 'thumbnail'> & {
    type: Required<Pick<ProductType, 'icon'>>;
  };
};

const getVariantThumbnail = <V extends Variant>({ thumbnail, product }: V): ImageFile =>
  thumbnail || product.thumbnail || product.type.icon;

export default getVariantThumbnail;
