import { Name } from 'types/models/Name';
import { NameSchemaType } from 'types/models/NameSchemaType';

import getAppName from 'utils/getAppName';

const getNameByType = (names: Name[] = [], typeId: NameSchemaType['id'] = 'TITLE', shopId = '1'): string =>
  names
    .find(({ nameSchemaTypeId }) => nameSchemaTypeId === typeId)
    ?.name?.replace('biznesowe.pl', getAppName(shopId)) || '';

export default getNameByType;
