import { useEffect } from 'react';
import useBoolean from 'hooks/useBoolean';

const useJavascriptEnabled = (): boolean => {
  const [isJavascriptEnabled, { on: onJavascriptEnabled }] = useBoolean(false);

  useEffect(onJavascriptEnabled, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isJavascriptEnabled;
};

export default useJavascriptEnabled;
