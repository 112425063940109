/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['top', 'middle', 'bottom', 'absolute', 'fixed', 'sticky'] }] */

import classNames from 'classnames';
import Loader from '@components/atoms/Loader';

import classes from './GlassLayer.module.scss';

type Props = {
  className?: string;
  loaderSize?: number;
  position?: 'absolute' | 'fixed' | 'sticky';
  placement?: 'top' | 'middle' | 'bottom';
};

const GlassLayer = ({
  className,
  loaderSize = 40,
  position = 'absolute',
  placement = 'middle',
}: Props): JSX.Element => (
  <div className={classNames(classes.glassLayer, classes[placement], classes[position], className)}>
    <Loader size={loaderSize} isDark />
  </div>
);

export default GlassLayer;
