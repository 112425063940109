import Price from 'types/Price';
import { EventParams } from 'types/Analytics';
import { ProductInCart } from 'types/ProductInCart';
import { getItems } from 'utils/analytics';

export const getViewCartsPayload = (
  type: EventParams['view_cart']['cart_type'],
  cartPrice: Price,
  products: ProductInCart[]
): EventParams['view_cart'] => ({
  cart_type: type,
  currency: cartPrice.currency,
  value: cartPrice.amount / 100,
  items: getItems(
    products.map((item) => ({
      ...item,
      grossPricePerUnit: item.grossPricePerUnit.amount,
    }))
  ),
});
