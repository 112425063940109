import keysOf from 'utils/keysOf';

const clearObject = <R extends object>(props: R): R => {
  const result = {} as R;
  keysOf(props).forEach((key) => {
    if (typeof props[key] !== 'undefined') {
      result[key] = props[key];
    }
  });

  return result;
};

export default clearObject;
