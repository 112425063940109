import { ClearCartRoute, GetCartRoute } from 'api/carts';
import { AddToCartRoute, RemoveFromCartRoute, UpdateOrderItemRoute } from 'api/carts/orderItems';
import { AddComplementaryToCartRoute } from 'api/carts/orderItems/complementary';
import ApiRoute from 'utils/apiRoute';

export const getCartRoute: GetCartRoute = new ApiRoute('/carts', 'GET');
export const clearCartRoute: ClearCartRoute = new ApiRoute('/carts', 'DELETE');

export const addToCartRoute: AddToCartRoute = new ApiRoute('/carts/orderItems', 'POST');
export const removeFromCartRoute: RemoveFromCartRoute = new ApiRoute('/carts/orderItems', 'DELETE');
export const updateOrderItemRoute: UpdateOrderItemRoute = new ApiRoute('/carts/orderItems', 'PUT');
export const addComplementaryToCartRoute: AddComplementaryToCartRoute = new ApiRoute(
  '/carts/orderItems/complementary',
  'POST'
);
