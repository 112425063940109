import { useEffect, useState, Fragment } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import { NOSCRIPT_CLASSES } from 'core/noscript.styles';
import SkeletonLogo from 'assets/icons/skeleton-logo.svg';
import SkeletonLoader from '@components/atoms/SkeletonLoader';
import createStyleVariables from 'utils/createStyleVariables';
import resolveImageUrl from 'utils/resolveImageUrl';
import pick from 'utils/pick';
import useJavascriptEnabled from 'hooks/useJavascriptEnabled';

import RawImage from './RawImage';
import {
  resolveSrcSetConfig,
  getDimension,
  getOriginalDimensions,
  getStringifiedImage,
  getSrcSet,
  getMimeType,
} from './BetterImage.utils';
import classes from './BetterImage.module.scss';
import Props from './BetterImage.types';

const skeletonTimeout = 300;

const AppImage = ({
  hasLazyLoad = true,
  hasFade = true,
  hasLoading = false,
  hasBlendMode = true,
  hasPreload = false,
  hasIncreasedDensity = true,
  classes: customClasses,
  className,
  display = 'block',
  resolveConfig,
  skeletonIcon = SkeletonLogo,
  skeletonIconWidth,
  skeletonIconHeight,
  skeletonBackground,
  skeletonIsExternal,
  layout,
  objectFit,
  width,
  height,
  ...props
}: Props): JSX.Element => {
  const [isLoad, setIsLoad] = useState(false);
  const [timeoutPassed, setTimeoutPassed] = useState(false);
  const isJavascriptEnabled = useJavascriptEnabled();
  const { ref, inView } = useInView({ triggerOnce: true, skip: !hasLazyLoad, initialInView: !hasLazyLoad });

  const isFillCover = layout === 'fill' && objectFit === 'cover';
  const withFiller = !hasLoading && !isLoad && !inView;

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (hasLoading && !timeoutPassed && !isLoad && (inView || !hasPreload)) {
      timer = setTimeout(() => setTimeoutPassed(true), skeletonTimeout);
    }

    return () => clearTimeout(timer);
  }, [hasLoading, timeoutPassed, isLoad, inView, hasLazyLoad, hasPreload]);

  const originalDimensions = getOriginalDimensions(resolveConfig);

  return (
    <span
      ref={ref}
      style={createStyleVariables({
        display,
        objectFit,
        width: width ? getDimension(width) : undefined,
        height: height ? getDimension(height) : undefined,
      })}
      className={classNames(className, customClasses?.root, classes.wrapper)}
    >
      <picture className={classNames(customClasses?.picture, isFillCover ? classes.fillCoverPicture : classes.picture)}>
        {inView &&
          (resolveConfig.srcSetConfig || []).flatMap((set, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={i}>
              <source
                media={`(min-width: ${set.minWidth}px)`}
                srcSet={resolveSrcSetConfig(resolveConfig, set)}
                type={getMimeType(resolveConfig)}
              />
              {resolveConfig.extension !== 'svg' && (
                <source
                  media={`(min-width: ${set.minWidth}px)`}
                  srcSet={resolveSrcSetConfig({ ...resolveConfig, overrideExtension: 'webp' }, set)}
                  type="image/webp"
                />
              )}
            </Fragment>
          ))}
        <source srcSet={getSrcSet(resolveConfig, hasIncreasedDensity)} type={getMimeType(resolveConfig)} />
        {resolveConfig.extension !== 'svg' && (
          <source
            srcSet={getSrcSet({ ...resolveConfig, overrideExtension: 'webp' }, hasIncreasedDensity)}
            type="image/webp"
          />
        )}
        {!isJavascriptEnabled && (
          <noscript
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: getStringifiedImage({
                className: classNames(classes.loaded, classes.objectFit, {
                  [classes.image]: hasBlendMode,
                  [classes.noscriptImageWithFiller]: withFiller,
                  [classes.fillCoverImage]: isFillCover,
                  [classes.imageScaling]: width || height,
                }),
                src: resolveImageUrl(resolveConfig),
                ...pick(props, ['title', 'alt']),
                ...originalDimensions,
              }),
            }}
          />
        )}
        {inView && (
          <RawImage
            className={classNames(classes.image, classes.objectFit, {
              [NOSCRIPT_CLASSES.HIDE]: !hasLazyLoad,
              [classes.imageScaling]: width || height,
              [classes.fillCoverImage]: isFillCover,
              [classes.hasFade]: hasFade,
              [classes.hasBlendMode]: hasBlendMode,
              [classes.loaded]: (!hasFade && !hasLoading) || isLoad || !hasLazyLoad,
            })}
            resolveConfig={resolveConfig}
            hasPreload={hasPreload}
            hasIncreasedDensity={hasIncreasedDensity}
            onLoad={() => setIsLoad(true)}
            {...originalDimensions}
            {...props}
          />
        )}
        {withFiller && <svg className={classes.filler} {...originalDimensions} />}
        {hasLoading && !isLoad && (
          <SkeletonLoader
            hasFade={false}
            icon={skeletonIcon}
            iconWidth={skeletonIconWidth || (typeof width === 'number' ? width : originalDimensions.width) || 48}
            iconHeight={skeletonIconHeight || (typeof height === 'number' ? height : originalDimensions.height) || 48}
            background={skeletonBackground}
            isExternal={skeletonIsExternal}
            isAnimationPuased={!inView || !timeoutPassed}
            className={classNames(classes.hasFade, classes.skeleton, NOSCRIPT_CLASSES.IMAGE_SKELETON, {
              [classes.hide]: !inView || !timeoutPassed,
            })}
          />
        )}
      </picture>
    </span>
  );
};

export default AppImage;
