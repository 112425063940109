import { ImgHTMLAttributes } from 'react';
import Head from 'next/head';

import resolveImageUrl, { ResolveConfig } from 'utils/resolveImageUrl';

import { getSrcSet, resolveSrcSetConfig } from './BetterImage.utils';

type Props = Pick<ImgHTMLAttributes<HTMLImageElement>, 'alt' | 'className' | 'width' | 'height' | 'title'> & {
  resolveConfig: ResolveConfig;
  hasPreload?: boolean;
  hasIncreasedDensity?: boolean;
  onLoad?: () => void;
};

const RawImage = ({
  alt,
  className,
  resolveConfig,
  hasPreload,
  hasIncreasedDensity = true,
  onLoad,
  ...props
}: Props): JSX.Element => (
  <>
    {hasPreload && (
      <Head>
        {resolveConfig.srcSetConfig ? (
          resolveConfig.srcSetConfig.map((set, i) => (
            <link
              key={`${resolveConfig.name}-${set.minWidth}`}
              rel="preload"
              as="image"
              href={resolveImageUrl({ ...resolveConfig, preset: set.preset })}
              imageSrcSet={resolveSrcSetConfig(resolveConfig, set)}
              media={
                !i
                  ? `(min-width: ${set.minWidth}px)`
                  : `(max-width: ${Number(resolveConfig?.srcSetConfig?.[i - 1].minWidth) - 1}px) and (min-width: ${
                      set.minWidth
                    }px)`
              }
            />
          ))
        ) : (
          <link
            key={resolveConfig.name}
            rel="preload"
            as="image"
            href={resolveImageUrl(resolveConfig)}
            imageSrcSet={getSrcSet(resolveConfig, hasIncreasedDensity)}
          />
        )}
      </Head>
    )}
    <img
      className={className}
      src={resolveImageUrl(resolveConfig)}
      srcSet={getSrcSet(resolveConfig, hasIncreasedDensity)}
      alt={alt}
      // @ts-expect-error this attribute is fine
      // eslint-disable-next-line react/no-unknown-property
      fetchpriority={hasPreload ? 'high' : undefined}
      onLoad={onLoad}
      {...props}
    />
  </>
);

export default RawImage;
